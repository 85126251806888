<script lang="ts">
	export let text: string;
	export let enable = true;
	export let type: 'primary' | 'secondary' = 'primary';

	let className = '';
	export { className as class };

	$: colorClass =
		type === 'primary'
			? enable == true
				? 'bg-secondary-600'
				: 'bg-gray-50'
			: enable == true
			? 'bg-orange1-700'
			: 'bg-orange1-900';
</script>

{#if enable}
	<div
	    role="button"
		tabindex="0"
	    on:keypress
		data-testid={`button-${text}`}
		class="flex px-6 py-3 items-center justify-center rounded {colorClass} text-primary-900 font-semibold {className}"
		on:click
	>
		<button>
			{text}
		</button>
	</div>
{:else}
	<div
		data-testid={`button-${text}`}
		class="flex px-6 py-3 items-center justify-center rounded {colorClass} text-primary-900 font-semibold {className}"
	>
		<button>
			{text}
		</button>
	</div>
{/if}

<style>
	button {
		border: none;
	}
</style>
