<script lang="ts">
	import { CloseCircleIcon, CloseIcon, InfoIcon, WarningIcon } from '$lib/components/icons';
	import DisplayIcon from '$lib/components/icons/DisplayIcon.svelte';
	import SecondaryButton from '../button/SecondaryButton.svelte';

	export let open = false;
	export let message = '';
	export let title = '';
	export let messageType = 'error';

	function mapIcon(messageType: string) {
		switch (messageType) {
			case 'error':
				return [CloseCircleIcon, 'red'];
			case 'info':
				return [InfoIcon, 'white'];
			case 'warn':
				return [WarningIcon, 'yellow'];
			default:
				break;
		}
		return [InfoIcon, 'white'];
	}

	$: icon = mapIcon(messageType);

	function toggle() {
		open = !open;
	}

	function handleKeydown(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			open = false;
		}
	}
</script>

<svelte:window on:keydown={handleKeydown} />
<div
	class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-40"
	class:opacity-0={!open}
	class:pointer-events-none={!open}
>
	<div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-90" />

	<div
		class="modal-container bg-primary-900 w-3/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
	>
		<div
			role="button"
			tabindex="0"
			on:keypress
			on:click={toggle}
			class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
		>
			<DisplayIcon size={18} icon={CloseIcon} css="fill-current text-white" />
			<span class="text-sm">(Esc)</span>
		</div>
		<div class="modal-content p-4 text-left">
			<div class="flex flex-row-reverse items-center">
				<div
					role="button"
					tabindex="0"
					on:keypress
					on:click={toggle}
					class="modal-close cursor-pointer z-50"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L14.8029 14.9999M15 1.00008L1.19707 15" stroke="white" />
					</svg>
				</div>
			</div>
			<div class="flex justify-around mt-6 text-white">
				<div class="flex flex-row">
					<p class="text-2xl font-medium">{title}</p>
				</div>
			</div>

			<!--Body-->
			<p class="text-white flex justify-around text-lg mt-8">{message}</p>

			<!--Footer-->
			<div class="flex justify-around my-8">
				<slot name="action">
					<SecondaryButton text="Close" on:click={toggle} />
				</slot>
			</div>
		</div>
	</div>
</div>

<style>
	.modal {
		transition: opacity 0.25s ease;
	}
</style>
